@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white-2;
  padding-left: 20px;
  padding-right: 20px;

  img {
    margin-top: 80px;
    margin-bottom: 24px;
    max-width: 457px;

    @include sm {
      max-width: 314px;
    }
  }

  .title {
    margin-bottom: 24px;
    text-align: center;
  }

  .subTitle {
    margin-bottom: 120px;
    text-align: center;
  }
}
.hiddenLabel {
  :global {
    legend {
      display: none !important;
    }
    label.Mui-focused {
      opacity: 0 !important;
    }
    label.MuiFormLabel-filled {
      opacity: 0 !important;
    }
  }
}
